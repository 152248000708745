import React, { useContext, useState } from "react";
import Carousel from 'framer-motion-carousel'
import * as types from "../models/Types";
import { GalleryContext } from "../models/Gallery";
import { useNavigate } from "react-router-dom";


const Newconstruction: React.FC<{ data: types.newconstructionType[] }> = ({
  data,
}) => {
  const navigate = useNavigate(); 
  const { setSelectedGallery} = useContext(GalleryContext);
  const openGallery = (title:string, photos:string[]) => {
    setSelectedGallery&&setSelectedGallery(photos)
    navigate(`/gallery/${title}`)
  }
  return (
    <div className="about my-4 py-4">
      <div className="new-construction">
        <h1 className="text-primary pb-3 text-center">New Construction</h1>
        {data && data.length ? (
          <Carousel autoPlay={false} interval={0} loop={true}>
            {data?.map((e: types.newconstructionType) => (
              <div className="row p-0 m-0 position-relative" key={e.title}>
                <div className="col-md-6 p-0 d-none d-md-block">
                  <img src={e.gallery[0]} alt={e.title} className="w-100" />
                </div>
                <div className="col-md-6 new-construction_box">
                  <img src={e.gallery[1]} alt={e.title} className="w-100" />
                </div>
                <div className="new-construction_absolute">
                  <div className="container position-relative">
                    <div className="new-construction_absolute--info">
                      <div className="m-4">
                        <h2 className="text-primary">{e?.title}</h2>
                        <h5>Location: {e?.location}</h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: e.description,
                          }}
                        ></p>
                        <p>
                          <strong>Status: {e?.status}</strong>
                        </p>
                        <div className="d-flex justify-content-end btns-container mt-5">
                          {e.units.length?<button
                            onClick={() => openGallery(`${e?.title} units`, e.units)}
                            className="btn btn-primary mx-2 more-btn flex-grow-1"
                          >
                            Units
                          </button>:null}

                          {e.plans.length?<button
                            onClick={() => openGallery(`${e?.title} plans`, e.plans)}
                            className="btn btn-primary mx-2 more-btn flex-grow-1"
                          >
                            Plans
                          </button>:null}
                          {e.gallery.length?<button
                            onClick={() => openGallery(`${e?.title} gallery`, e.gallery)}
                            className="btn btn-primary mx-2 more-btn flex-grow-1"
                          >
                            Gallery
                          </button>:null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        ) : (
          <div>"Loading..."</div>
        )}
      </div>
    </div>
  );
};

export default Newconstruction;
