import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import Alert from '@mui/material/Alert';

type FormData = {
  firstname: string;
  lastname: string;
  email: string;
  message: string;
};

const Contact = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const [mailSentMsg, setmailSentMsg] = useState<{message?:string,status?:"success"|"error"}>({});

  const onSubmit = (formData: FormData) => {
    axios({
      method: "post",
      url: "https://www.k3greekproperties.com/api/",
      headers: { "content-type": "application/json" },
      data: formData,
    })
      .then((result) => {
        if (result.data.message) {
          setmailSentMsg(result.data);
        }else{
          setmailSentMsg({message:"Email not sent",status:"error"});
        }
      })
      .catch((error) => setmailSentMsg(error.data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="row">
          <div className="contact-form col-md-6 d-flex flex-column align-items-center">
        {mailSentMsg.status?<div className="messages mb-5 w-100" style={{fontSize:"1.3rem"}}>
        <Alert severity={mailSentMsg.status}>{mailSentMsg.message}</Alert>
        </div>:null}
            <div className="form-group mb-4 w-100">
              <label>Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Full name"
                {...register("firstname", { required: true })}
              />
            </div>
            <div className="form-group mb-4 w-100">
              <label>Last name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                {...register("lastname", { required: true })}
              />
            </div>
            <div className="form-group mb-4 w-100">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Please enter your email"
                {...register("email", { required: true })}
              />
            </div>
            <div className="form-group mb-4 w-100">
              <label htmlFor="form_message">Message</label>
              <textarea
                className="form-control"
                placeholder="Message us"
                {...register("message", { required: true })}
              ></textarea>
            </div>
            <div className="form-group mb-4 d-flex justify-content-end w-100">
              <input
                type="submit"
                className="btn btn-primary btn-send"
                value="Send message"
              />
            </div>
          </div>
          <div className="contact-form col-md-1"></div>
          <div className="contact-form col-md-5 bg-light p-5">
            <h1>INTERESTED?</h1>
            <h3>LET'S TALK</h3>
            <p>Leave us your contact details, or contact us now</p>
                <a href="tel:96170227477" target="_blank" rel="noreferrer" className="d-flex align-items-center mb-3">
                  <img src="images/lebanon.png" alt="Lebanon" width="25" />
                  <div className="ms-3">+961-70-227477</div>
                </a>
                <a href="tel:306970869326" target="_blank" rel="noreferrer" className="d-flex align-items-center mb-3">
                  <img src="images/greece.png" alt="Greece" width="25" />
                  <div className="ms-3">+30-699-501-1111</div>
                </a>
            <a
              href="https://www.facebook.com/profile.php?id=61552845783664&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center mb-3"
            >
              <img
                src="images/facebook.png"
                alt="k3greekproperties"
                className="header_social--icon"
              />
              <div className="ms-3">https://www.facebook.com/</div>
            </a>
            <a
              href="https://www.instagram.com/k3greekproperties/"
              target="_blank"
              rel="noreferrer"
              className="d-flex align-items-center mb-3"
            >
              <img
                src="images/instagram.png"
                alt="k3greekproperties"
                className="header_social--icon"
              />
              <div className="ms-3">https://www.instagram.com/k3greekproperties/</div>
            </a>
            <a href="mailto:info@k3greekproperties.com"
              className="d-flex align-items-center mb-3">
              <img
                src="images/envelope-48.png"
                alt="info@k3greekproperties.com"
                className="header_social--icon"
              />
              <div className="ms-3">info@k3greekproperties.com</div>
            </a>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Contact;
