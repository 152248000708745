
import { useLocation } from 'react-router-dom';

type aboutType = {
  img: string;
  content: { id: number; title: string; text: string }[];
};

const About: React.FC<{ data: aboutType|null }> = ({data}) => {
  let location = useLocation();
  return <div className="about my-4 py-4">
      {data ? <div className="container">
        <div className={`pb-5 d-flex flex-column flex-md-row align-items-center ${location.pathname==="/about"&& "w-80 mx-auto"}`}>
          <div className="ms-md-4 mb-4 mb-md-0">
            {data?.content.map((e:any) => (
              <div key={e.id}>
                <h2 className="text-primary">{e.title}</h2>
                <p dangerouslySetInnerHTML={{ __html: e.text }}></p>
              </div>
            ))}
          </div>
          {location.pathname!=="/about"?<div className="w-80 w-md-40 ms-md-5">
            <img src={data?.img} alt="" width="100%" />
          </div>:null}
        </div>
      </div> : <div>"Loading..."</div>
  }
    </div>
  
};

export default About;