import type { ReactNode, FC } from "react";
import { Gallery } from "./Gallery";

type GlobalProps = {
  children: ReactNode;
};

const ContextGlobalProvider: FC<GlobalProps> = ({ children }) => {
    return <Gallery>{children}</Gallery>
};

export default ContextGlobalProvider;
