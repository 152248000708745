const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid text-center">
            K3 Greek Properties. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;