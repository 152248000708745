import { Box, Drawer, Popover } from "@mui/material";
import { useState } from "react";
import { Menu, Phone } from "@mui/icons-material";
import {useLocation} from 'react-router-dom';


interface HeaderProps {
  color: string;
  children?: React.ReactNode;
}
type Anchor = "top";

const Header: React.FC<HeaderProps> = ({ color }) => {
  const location = useLocation();
  
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [state, setState] = useState({ top: false });
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="d-flex flex-sm-row flex-column">
        <div className="header_navbar d-flex flex-column flex-sm-row">
          <a href="/" className="fw-bold">
            Home
          </a>
          <a href="/about" className="fw-bold">
            About
          </a>
          <a href="/projects" className="fw-bold">
            Projects
          </a>
          <a href="/contact" className="fw-bold">
            Contact Us
          </a>
        </div>
        <div className="mx-auto me-sm-0 header_social d-flex">
            <a
              href="https://www.facebook.com/profile.php?id=61552845783664&mibextid=LQQJ4d"
              target="_blank"
            rel="noreferrer"
          >
            <img
              src="images/facebook.png"
              alt="k3greekproperties"
              width="25"
            />
          </a>
          <a
            href="https://www.instagram.com/k3greekproperties/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="images/instagram.png"
              alt="k3greekproperties"
              width="25"
            />
          </a>
          <a href="mailto:info@k3greekproperties.com">
            <img
              src="images/envelope-48.png"
              alt="info@k3greekproperties.com"
              width="25"
            />
          </a>
            <button
              onClick={handleClick}
              className="btn header_social--icon"
            >
              <Phone />
            </button>
        </div>
      </div>
    </Box>
  );
console.log("window.innerWidth",window.innerWidth)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <header className="header">
      <div className="container">
        <div className="d-flex">
          <div className="header_navbar d-flex">
            <button
              onClick={toggleDrawer("top", true)}
              className="btn fw-bold d-sm-none"
            >
              <Menu style={{ fontSize: "30px" }} />
            </button>
            <Drawer
              anchor="top"
              open={state["top"]}
              onClose={toggleDrawer("top", false)}
            >
              {list("top")}
            </Drawer>
            <a href="/" className="fw-bold d-none d-sm-flex">
              Home
            </a>
            <a href="/about" className="fw-bold d-none d-sm-flex">
              About
            </a>
            <a href="/projects" className="fw-bold d-none d-sm-flex">
              Projects
            </a>
          <a href="/contact" className="fw-bold d-none d-sm-flex">
            Contact Us
          </a>
          </div>
          <div className="ms-auto header_social d-flex">
            <a
              href="https://www.facebook.com/profile.php?id=61552845783664&mibextid=LQQJ4d"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/facebook.png"
                alt="k3greekproperties"
                className="header_social--icon"
              />
            </a>
            <a
              href="https://www.instagram.com/k3greekproperties/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/instagram.png"
                alt="k3greekproperties"
                className="header_social--icon"
              />
            </a>
            <a href="mailto:info@k3greekproperties.com">
              <img
                src="/images/envelope-48.png"
                alt="info@k3greekproperties.com"
                className="header_social--icon"
              />
            </a>
            <button
              onClick={handleClick}
              className="btn header_social--icon"
            >
              <Phone />
            </button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="popover-container p-3">
                <a href="tel:96170227477" target="_blank" rel="noreferrer">
                  <img src="images/lebanon.png" alt="Lebanon" width="25" />
                  &nbsp; +961-70-227477
                </a>
              </div>
              <div className="popover-container p-3">
                <a href="tel:306970869326" target="_blank" rel="noreferrer">
                  <img src="images/greece.png" alt="Greece" width="25" />
                  &nbsp; +30-699-501-1111
                </a>
              </div>
            </Popover>
          </div>
        </div>
      </div>
      {location.pathname!=="/"?<div className="mb-5"><img src={window.innerWidth>768?`/images/logo-inside.jpg`:`/images/real-logo.jpg`} alt="" width="100%" /></div>:null}
    </header>
  );
};

export default Header;
