import {
    ReactNode,
    FC,
    createContext,
    useState,
    useMemo
} from 'react';

type GalleryContextType = {
    selectedGallery?: string[];
    setSelectedGallery?: React.Dispatch<React.SetStateAction<string[]>>;
};

const GalleryContext = createContext<GalleryContextType>({});


const Gallery: FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedGallery, setSelectedGallery] = useState<string[]>([]);

    const galleryProviderValue = useMemo(
        () => ({ selectedGallery, setSelectedGallery }),
        [selectedGallery, setSelectedGallery]);

    return <GalleryContext.Provider value={ galleryProviderValue }>
        { children }
        </GalleryContext.Provider>
};

export { Gallery, GalleryContext };


