export const Slider = () => {
  return (
    <div className="homepage-slider d-flex align-items-center">
      <div className="container">
        <div className="d-flex justify-content-center justify-content-md-start">
          <img src="images/logo.png" alt="K3 Greek" className="homepage-slider_logo p-4" />
        </div>
      </div>
    </div>
  );
};
