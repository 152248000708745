
import { useEffect, useState } from "react";
import AboutComponent from "../components/About";
import * as types from "../models/Types";
import axios from "axios";
  
  const About = () => {
    const [about, setAbout] = useState<types.aboutType | null>(null);
  
    useEffect(() => {
      axios.get(`json/about.json`).then((res: any) => {
        setAbout(res.data);
      });
    }, []);

    return <>
    <AboutComponent data={about} />
    </>
    
  };
  
  export default About;