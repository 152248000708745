import React, { useState, useContext } from "react";
import { Modal, Box } from "@mui/material";
import { GalleryContext } from "../models/Gallery";
import { useNavigate } from "react-router-dom";

type renovatedType = {
  title: string;
  Reference: string;
  Location: string;
  Area: string;
  Floor: string;
  Description: string;
  Bedrooms: string;
  Bathrooms: string;
  External: string;
  Parking: string;
  Elevator: string;
  "Building Construction Year": string;
  "Apartment Renovation Year": string;
  Status: string;
  Availability: string;
  Photos: string[];
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.matchMedia('(min-width: 768px)').matches?"60%":"90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};

const Renovated: React.FC<{ data: renovatedType[] }> = ({ data }) => {
  const navigate = useNavigate(); 
  const { setSelectedGallery} = useContext(GalleryContext);
  const openGallery = (title:string, photos:string[]) => {
    setSelectedGallery&&setSelectedGallery(photos)
    navigate(`/gallery/${title}`)
  }
  const [openModal, setOpenModal] = useState(false);
  const [dataSelected, setDataSelected] = useState<any>({});
  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setDataSelected(data);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setDataSelected({});
  };
  return <div className="renovated-apt my-5 py-5">
      {data && data.length ? (
        <div className="container position-relative">
          <h1 className="text-primary pb-3 text-center">
            Renovated Apartments
          </h1>
          <div className="row">
            {data.map((e: renovatedType, i: number) => (
              <div className="col-6 col-md-2 mb-4" key={i}>
                <div
                  className="renovated-apt_img position-relative"
                  
                >
                  <img
                    src={
                      e?.Photos.length
                        ? e.Photos[0]
                        : "images/projects/no-img.jpg"
                    }
                    alt=""
                  />
                  <div className="renovated-apt_img--title" onClick={() => handleOpenModal(e)}>
                    <h3>{e.title}</h3>
                    <div className="d-flex justify-content-center">
                    <button className="btn btn-primary ms-3 more-btn">View Details</button>
                    </div>
                  </div>
                  <div
                    className={`renovated-apt_availability ${
                      e.Availability === "Available"
                        ? "bg-success"
                        : "bg-danger"
                    }`}
                  >
                    {e.Availability}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Modal open={openModal} onClose={handleCloseModal} style={{zIndex:1000000}}>
            <>
            <Box sx={style}>
              <strong>Title:</strong> {dataSelected["title"]}
              <br />
              <strong>Reference:</strong> {dataSelected["Reference"]}
              <br />
              <strong>Location:</strong> {dataSelected["Location"]}
              <br />
              <strong>Area:</strong> {dataSelected["Area"]}
              <br />
              <strong>Floor:</strong> {dataSelected["Floor"]}
              <br />
              <strong>Description:</strong> {dataSelected["Description"]}
              <br />
              <strong>Bedrooms:</strong> {dataSelected["Bedrooms"]}
              <br />
              <strong>Bathrooms:</strong> {dataSelected["Bathrooms"]}
              <br />
              <strong>External:</strong> {dataSelected["External"]}
              <br />
              <strong>Parking:</strong> {dataSelected["Parking"]}
              <br />
              <strong>Elevator:</strong> {dataSelected["Elevator"]}
              <br />
              <strong>Building Construction Year:</strong>{" "}
              {dataSelected["Building Construction Year"]}
              <br />
              <strong>Apartment Renovation Year:</strong>{" "}
              {dataSelected["Apartment Renovation Year"]}
              <br />
              <strong>Status:</strong> {dataSelected["Status"]}
              <br />
              <strong>Availability:</strong> {dataSelected["Availability"]}
              <br /><br />
              <button
                onClick={() => openGallery(dataSelected["title"], dataSelected["Photos"])}
                className="btn btn-primary more-btn"
              >
                View Gallery
              </button>
            </Box>
          </>
          </Modal>
        </div>
      ) : (
        <div>"Loading..."</div>
      )}
    </div>
};

export default Renovated;
