import { useContext } from "react";
import { useParams } from "react-router-dom";
import LightGallery from 'lightgallery/react';
import { GalleryContext } from "../models/Gallery";

// If you want you can use SCSS instead of css
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import lgZoom from 'lightgallery/plugins/zoom';
import { useNavigate } from "react-router-dom";

const Gallery = () => {
  const navigate = useNavigate(); 
  const { title } = useParams();
  const { selectedGallery} = useContext(GalleryContext);
  if(!selectedGallery || selectedGallery.length===0){
    window.location.href="/"
  }

  return <div className="container">
    <h1>{title}</h1>
    <hr className="hr" />
    <p>Click thumbnails to enlarge</p>
    <LightGallery
      speed={500}
      plugins={[lgZoom]}
      elementClassNames="d-flex flex-wrap"
    >
      {
      selectedGallery?selectedGallery.map((e:string,i:number)=><a href={e} key={i} className="m-2 d-flex align-items-center bg-secondary">
        <img src={e} width="200" />
      </a>):null
      }
    </LightGallery>
  </div>
};

export default Gallery;