
import ReactDOM from "react-dom/client";
import Header from "./common/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles.scss";
import Footer from "./common/Footer";
import {BrowserRouter,Route, Routes} from "react-router-dom"
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import ContextGlobalProvide from './models/ContextGlobalProvider';


const el = document.getElementById("root");
const root = ReactDOM.createRoot(el!);

const App = () => {
  return (
    <ContextGlobalProvide>
    <BrowserRouter>
      <Header color="red" />
      <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/gallery/:title" element={<Gallery />} />
      <Route path="/contact" element={<Contact />} />
      
      </Routes>
      
      
      <Footer />
    </BrowserRouter>
    </ContextGlobalProvide>
  );
};

export default App;

root.render(<App />);
