
import { useEffect, useState } from "react";
import { Slider } from "../components/Slider";
import About from "../components/About";
import Newconstruction from "../components/Newconstruction";
import Renovated from "../components/Renovated";
import * as types from "../models/Types";
import axios from "axios";
  
  const Homepage = () => {
    const [about, setAbout] = useState<types.aboutType | null>(null);
    const [renovated, setRenovated] = useState<types.renovatedType[]>([]);
    const [newconstruction, setNewconstruction] =
      useState<types.newconstructionType[]>([]);
  
    useEffect(() => {
      axios.get(`json/about.json`).then((res: any) => {
        setAbout(res.data);
      });
      axios.get(`json/renovated.json`).then((res: any) => {
        setRenovated(res.data);
      });
      axios.get(`json/newconstruction.json`).then((res: any) => {
        setNewconstruction(res.data);
      });
    }, []);

    return <>
    <Slider />
    <About data={about} />
    <Newconstruction data={newconstruction} />
    <Renovated data={renovated} />
    </>
    
  };
  
  export default Homepage;